import React, { useState } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import ResourcesRegistrationForm from "src/components/Form/ResourcesRegistrationForm"
import BaseButton from "src/components/Base/BaseButton"
import BaseIcon from "src/components/Base/BaseIcon"
import { SecondaryTitle } from "src/components/Typography"

const ResourcesRegistrationPopup = ({
  resourceTitle,
  downloadButtonLabel,
  downloadUrl,
  previewButtonLabel,
  previewUrl,
  visible,
  onDownload,
  onClose,
}) => {
  const [registered, setRegistered] = useState(false)

  function handleClose() {
    onClose()
  }

  function handleRegister() {
    setRegistered(true)
  }

  return (
    <Wrapper className={visible ? "-visible" : "-hidden"}>
      <Popup>
        <PopupInner>
          <Header>
            <Title>
              {registered ? "View" : "Register for the Resource library"}
            </Title>
            <Subtitle>
              {registered ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `Thank you! Here’s how to access <strong>${resourceTitle}</strong>:`,
                  }}
                />
              ) : (
                `Thank you for using our Resource library! Please fill out the form below – we would like to know more about you.`
              )}
            </Subtitle>
          </Header>
          {registered ? (
            <>
              <DownloadButton
                label={downloadButtonLabel}
                to={downloadUrl}
                onClick={onDownload}
              />
              {previewUrl && (
                <PreviewButton
                  label={previewButtonLabel}
                  variant="outline"
                  to={previewUrl}
                  onClick={onDownload}
                />
              )}
              <License>
                <strong>License</strong> Anima International allows you to use,
                download, share, copy and redistribute the materials and
                resources on this website in any medium or format. You may also
                adapt, remix, transform, and build upon the material for any
                purpose, even commercially, no attribution or credit towards
                Anima International is needed. The materials on this website are
                copyright-free, including text, images, audio, video, software
                or other content that is made available on this website. You may
                use this website’s content for but not limited to, events,
                campaigns, lobbying, PR as well as fundraising purposes. You are
                responsible for ensuring that your use of content from this
                website is lawful.
              </License>
            </>
          ) : (
            <ResourcesRegistrationForm onRegister={handleRegister} />
          )}
          <CloseButton onClick={handleClose}>
            <BaseIcon name="x" source="feather" />
          </CloseButton>
        </PopupInner>
      </Popup>
      <Overlay onClick={handleClose} />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Popup = styled.section`
  display: flex;
  align-items: center;
  width: 640px;
  max-width: 100vw;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  pointer-events: none;
  transition: 0.25s;

  ${Wrapper}.-hidden & {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
  }

  @media (max-height: 650px) {
    align-items: flex-start;
    overflow: auto;
    pointer-events: all;
  }
`

const PopupInner = styled.div`
  width: 100%;
  padding: 30px ${(p) => p.theme.sidePadding.phone}px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 40px 80px rgba(0, 11, 33, 0.08);
  pointer-events: all;

  ${media.tablet} {
    padding: 40px ${(p) => p.theme.sidePadding.tablet}px;
  }

  ${media.desktop} {
    padding: 50px ${(p) => p.theme.sidePadding.desktop}px;
  }
`

const Header = styled.header`
  margin-bottom: 40px;

  ${media.tablet} {
    margin-bottom: 60px;
  }
`

const Title = styled(SecondaryTitle)`
  margin-right: 40px;
  margin-bottom: 20px;
`

const Subtitle = styled.p`
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};

  strong {
    font-weight: 600;
    color: #000;
  }
`

const License = styled.div`
  margin-top: 60px;
  font-size: 13px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.textLight};

  strong {
    display: block;
    margin-bottom: 6px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    // color: #8c9d9d;
    color: ${(p) => p.theme.color.textLight};
  }
`

const DownloadButton = styled(BaseButton)`
  margin-right: 10px;
`

const PreviewButton = styled(BaseButton)`
  margin-top: 10px;

  ${media.tablet} {
    margin-top: 0;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 11, 33, 0.3);
  transition: 0.25s;

  ${Wrapper}.-hidden & {
    visibility: hidden;
    opacity: 0;
  }
`

const CloseButton = styled.button`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  background: #f3f6f6;
  border-radius: 0 3px 0 3px;
  /* background: #fff; */
  /* border-radius: 50px; */
  /* box-shadow: 0 20px 40px rgba(0, 11, 33, 0.08); */
  color: ${(p) => p.theme.color.primary};
  /* color: #74737d; */
  transition: background 0.15s;

  &:hover {
    background: #e8ecec;
  }
`

export default ResourcesRegistrationPopup

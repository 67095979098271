import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"
import BaseButton from "src/components/Base/BaseButton"
import countryList from "../../../data/country-list"

const ResourcesRegistrationForm = ({ onRegister }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [organisation, setOrganisation] = useState("")
  const [country, setCountry] = useState("")

  function getApiFetches() {
    return [
      fetch("https://crm.otwarteklatki.pl/graphql", {
        headers: new Headers({ "content-type": "application/json" }),
        method: "POST",
        body: JSON.stringify({
          query: `
            mutation SignPetition($input: SignPetitionInput!) {
              SignPetition(input: $input) {
                success
                fullErrorMessages
                sign {
                  signerName signerEmail
                  petition {
                    signsCount
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              petitionGuid: "0505641a-5d3a-45a5-8ef0-d90c390a9fbd",
              signerName: name,
              signerEmail: email,
              newsletterConfirmed: true,
            },
          },
        }),
      }),
      fetch("https://api.animainternational.org/user-cookies", {
        method: "POST",
        body: JSON.stringify({
          name,
          email,
          organisation,
          country,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ]
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (typeof fetch === "undefined") {
      alert(
        "Sorry, your browser doesn’t support this feature. Please upgrade your browser."
      )
      return
    }

    Promise.all(getApiFetches())
      .then(([_crmResponse, strapiResponse]) => {
        if (strapiResponse.ok) {
          return strapiResponse.json()
        } else {
          return Promise.reject({
            status: strapiResponse.status,
            statusText: strapiResponse.statusText,
          })
        }
      })
      .then((response) => {
        localStorage.setItem("resourceLibraryUser", JSON.stringify(response))
        onRegister()
      })
      .catch((error) => console.log("Error:", error.statusText))
  }

  return (
    <Wrapper action="" onSubmit={handleSubmit}>
      <FieldWrapper>
        <Input
          value={name}
          placeholder="Your name"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <FieldIcon name="user" source="feather" />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          type="email"
          value={email}
          placeholder="Email address"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <FieldIcon name="send" source="feather" />
      </FieldWrapper>
      <FieldWrapper>
        <Select
          as="select"
          value={country}
          className={country ? "-filled" : ""}
          required
          onChange={(e) => setCountry(e.target.value)}
        >
          <Option value="">Country</Option>
          {countryList.map((country, index) => (
            <Option key={index}>{country}</Option>
          ))}
        </Select>
        <FieldIcon name="globe" source="feather" />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          value={organisation}
          placeholder="Organisation"
          onChange={(e) => setOrganisation(e.target.value)}
        />
        <FieldIcon name="flag" source="feather" />
        <OptionalMark>optional</OptionalMark>
      </FieldWrapper>
      <FieldWrapper>
        <Conditions>
          <input type="checkbox" required /> I agree to the terms and conditions
          of usage reflected on the{" "}
          <StyledLink to="/terms-of-use">Terms of use</StyledLink>.
        </Conditions>
        <Conditions>
          <input type="checkbox" required /> I consent Animal International to
          process my personal data as described in the{" "}
          <StyledLink to="/privacy-policy">Private policy</StyledLink>. I
          understand that I can withdraw my consent at any time.
        </Conditions>
      </FieldWrapper>
      <FieldWrapper>
        <Button type="submit" label="Register" />
      </FieldWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const FieldWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
`

const Input = styled.input`
  width: 100%;
  padding: 12px 25px 11px;
  padding-left: 58px;
  border: 1px solid #cfdbdb;
  border-radius: 3px;
  color: #000;
  transition: 0.15s;
  transition-property: border-color, box-shadow;

  &::placeholder {
    color: rgba(116, 115, 125, 0.75);
  }

  &:focus {
    border-color: #3ab6da;
    box-shadow: 0 0 30px hsla(179, 64%, 58%, 0.1),
      0 0 5px hsla(179, 64%, 58%, 0.35);

    &::placeholder {
      color: rgba(116, 115, 125, 0.5);
    }
  }

  ${media.tablet} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const Select = styled(Input)`
  color: rgba(116, 115, 125, 0.75);

  &.-filled {
    color: #000;
  }
`

const Option = styled.option``

const OptionalMark = styled.span`
  position: absolute;
  top: 0;
  right: 25px;
  line-height: 50px;
  font-size: 13px;
  /* color: ${(p) => p.theme.color.lightPlum}; */
  color: #8c9d9d;
  pointer-events: none;
`

const FieldIcon = styled(BaseIcon)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${(p) => (p.name === "user" ? "15px" : "17px")};
  left: 20px;
  color: #cfdbdb;
  pointer-events: none;
  width: ${(p) => (p.name === "user" ? "19px" : "18px")};
  height: ${(p) => (p.name === "user" ? "19px" : "18px")};
`

const Conditions = styled.label`
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
  font-size: 13px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.textLight};
  user-select: none;

  &:nth-of-type(n + 2) {
    margin-top: -15px;
  }

  &::after {
    content: "*";
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 12px;
    line-height: 1;
    font-family: ${(p) => p.theme.font.mono};
    color: #c00;
  }

  input {
    appearance: checkbox;
    position: absolute;
    top: 3px;
    left: 0;
  }
`

const Button = styled(BaseButton)``

const StyledLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`

export default ResourcesRegistrationForm

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPostAuthor = ({
  name,
  bio,
  imageUrl,
  imageSharp,
  twitterUsername,
  className,
  path,
}) => (
  <Author className={className}>
    <AuthorPhotoWrapper to={path}>
      {imageSharp ? (
        <AuthorPhotoSharp image={imageSharp.gatsbyImageData} alt={name} />
      ) : imageUrl ? (
        <AuthorPhoto src={imageUrl} alt={name} />
      ) : null}
    </AuthorPhotoWrapper>
    <AuthorInfo>
      <AuthorName>
        {(path && <AuthorLink to={path}>{name}</AuthorLink>) || name}
      </AuthorName>
      <AuthorBio>{bio}</AuthorBio>
      {twitterUsername && (
        <AuthorSocialMedia>
          <a
            href={`https://twitter.com/${twitterUsername}?ref_src=twsrc%5Etfw`}
            className="twitter-follow-button"
            data-show-count="false"
          >
            {""}
          </a>
        </AuthorSocialMedia>
      )}
    </AuthorInfo>
  </Author>
)

const Author = styled.div`
  margin-top: 100px;
  font-size: 15px;
  line-height: ${(p) => p.theme.baseLineHeight};
  font-family: ${(p) => p.theme.font.primary};
  color: ${(p) => p.theme.color.textLight};

  ${media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
  }
`

const AuthorPhotoWrapper = styled(Link)`
  display: block;
  width: 100px;
  min-width: 100px;
  height: 100px;
  margin-top: 6px;
  margin-right: 32px;
  margin-bottom: 20px;
  padding: 3px;
  position: relative;
  background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 50%;
  /* box-shadow: 0 16px 16px rgba(3, 34, 33, 0.08); */

  &[href="/"] {
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border: 5px solid #fff;
    border-radius: 50%;
  }

  ${media.tablet} {
    margin-bottom: 0;
  }
`

const AuthorPhoto = styled.img`
  width: 94px;
  border: 5px solid #fff;
  border-radius: 50%;
`

const AuthorPhotoSharp = styled(GatsbyImage)`
  width: 94px !important;
  height: 94px !important;
  border: 5px solid #fff;

  &,
  img {
    border-radius: 50%;
  }
`

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AuthorName = styled.h4`
  margin-bottom: 10px;
  font-weight: 500;
  color: #000;
`

const AuthorLink = styled(Link)``

const AuthorBio = styled.p``

const AuthorSocialMedia = styled.div`
  margin-top: 30px;
`

export default BlogPostAuthor
